@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

body {
    font-family: 'Poppins', sans-serif;
}

.header {
    height: 100vh;
    background-color: #1d2431;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

}

.navigation {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo-image {
    width: 300px;
}

.navigation__right-side {
    display: flex;
    text-transform: uppercase;
}

.language {
    padding: 5px;
    border: 1px solid white;
    color: white;
    width: 50px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    cursor: pointer;
}

.language.active {
    background-color: white;
    color: #1d2431;
}

.main-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-logo {
    width: 400px;
}

.main-logo__hand {
    width: 600px;
}

.prices-table-mobile {
    display: none;
}

.prices-table {
    border-collapse: collapse;
    width: 85%;
    /*border: 1px solid #fff;*/
}

.prices-table .tall {
    font-size: 52px;
    font-weight: bold;
}

.prices-table td, th {
    text-align: center;
    vertical-align: middle;
    padding: 20px;
    border: 1px solid #fff;
}


.prices-table p {
    width: 100%;
}

.prices-table td .text-centered {
    text-align: center;
}

.centered {
    display: flex;
    justify-content: center;
}

.text-content {
    letter-spacing: 2px;
    line-height: 60px;
    font-weight: bold;
    color: white;
    text-align: center;
    font-size: 50px;
    margin: 0 auto;
}

/* .hand-logo {
transform: translateY(-50px);
} */

.hand-text {
    color: white;
    width: 70%;
    text-align: center;
    margin: 0 auto;
}

/* .hand-text__modify {
  transform: translateY(-50px);
} */
.hand-text__highlight {
    font-weight: bold;
}

.text-content p {
    margin: 20px 0 0 0;
}

.logo-content__main-container {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
}

.logo-content__main-header, .logo-content__main-text {
    text-align: left;
    margin: 0;
}

.logo-content__contact {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.logo-content__contact-input {
    background-color: transparent;
    border: 1px solid white;
    padding: 5px 0 5px 20px;

    color: white;
    width: 400px;
    border-radius: 10px;
    font-size: 30px;
    height: 30px;
    font-weight: normal;
    margin-right: 10px;
}

.logo-content__contact-input:focus {
    outline: none;
}

.logo-content__contact-input::placeholder {
    color: white;
    font-weight: 300;
}

.image-container {
    width: 60%;
    display: flex;
    justify-content: end;
}

.monitor-logo {
    width: 500px;
    margin: 0 0 0 auto !important;
}

.logo-content__main {
    display: flex;
    width: 80%;
    margin: 70px auto 0 auto;
}

.logo-content__contact-button {
    width: 150px;
    padding: 5px 15px;
    text-transform: uppercase;
    color: black;
    background-color: #53a9ff;
    border-radius: 10px;
    border: none;
    letter-spacing: 4px;
    font-weight: 700;
    font-size: 25px;
    cursor: pointer;
}

.footer-section {
    /* margin-top: 20px; */
    width: 90%;
    padding: 15px 0;
    max-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-image {
    width: 25%;
}

.footer-logo {
    width: 100%;
}

.footer-text {
    width: 50%;
    color: white;
    font-size: 8px;
    padding-left: 10px;
}

.footer-socials {
    min-width: 15%;
    margin-left: 20px;
}

.logo {
    color: white;
    margin-left: 12px;
    width: 30px
}


/* SLIDER */

.slick-slide img {
    margin: 0 auto;
    display: block;
}

.slick-dots li button {
    width: 10px !important;
    height: 10px !important;
    background-color: transparent !important;
    border: 1px solid white !important;
    border-radius: 50% !important;
}

.slick-dots li button:before {
    width: 10px !important;
    height: 10px !important;
    color: transparent !important;
}

.slick-dots li.slick-active button:before {
    width: 12px !important;
    height: 12px !important;
    color: white !important;
    opacity: 1 !important;
    border-radius: 50% !important;
    background-color: white !important;
}


@media (max-width: 912px) {
    .prices-table {
        display: none;
    }

    .prices-table-mobile {
        display: table;
        border-collapse: collapse;
        width: 100%;
        margin-bottom: 20px;
        /*border: 1px solid #fff;*/
    }

    .prices-table-mobile .tall {
        font-size: 52px;
        font-weight: bold;
    }

    .prices-table-mobile td, th {
        text-align: center;
        vertical-align: middle;
        border: 1px solid #fff;
    }

    .prices-table-mobile p {
        width: 100%;
    }

    .prices-table-mobile td .text-centered {
        text-align: center;
    }

    .header {
        overflow-y: scroll;
    }

    .logo-image {
        width: 100%;
    }

    .navigation__right-side .en {
        margin-bottom: 5px;
    }

    .logo-content.first-slide {
        height: 1000px !important;
    }

    .language {
        width: 100px;
        height: 30px;
        font-size: 20px;
    }

    .main-content {
        height: min-content;
    }

    .main-logo {
        width: 70%;
    }

    .hand-text {
        font-size: 30px;
        width: 90%;
    }

    .text-content.first-slide {
        margin-bottom: 20px;
    }

    .logo-content__main {
        flex-direction: column;
        width: 90%;
        align-items: center;
    }

    .monitor-logo {
        width: 500px;
    }

    .logo-content__main-container {
        width: 100%;
        align-items: center;
        margin-top: 60px;
    }

    .logo-content__main-text {
        text-align: center;
        margin-top: 100px;
    }


    .footer-section {
        flex-direction: column;
        width: 90%;
        height: min-content;
        align-items: flex-start;
        margin-top: 40px;
        max-height: none;
    }

    .footer-image {
        order: 2;
    }

    .footer-socials {
        order: 1;
        margin-left: 20px;
    }

    .footer-text {
        order: 3;
        margin-left: 30px;
        font-size: 20px;
        width: 100%;
        margin-top: 0;
        padding-left: 0px;
    }

    .footer-logo {
        width: 450px;
    }

    .logo {
        width: 60px;
    }
}

@media (max-width: 450px) {

    .logo-image {
        width: 70%;
    }

    .navigation__right-side {
        flex-direction: column;
    }

    .language {
        width: 50px;
        height: 15px;
        font-size: 15px;
    }

    .text-content {
        font-size: 30px;
        line-height: 40px;
    }

    .text-content.first-slide {
        margin-top: 40px;
    }

    .hand-text {
        font-size: 15px;
    }

    .hand-text.first-slide {
        margin-top: 40px;
    }

    .main-logo__hand {
        width: 100%;
    }

    .monitor-logo {
        width: 300px;
    }

    .image-container {
        display: flex;
        justify-content: center;
    }

    .hand-text__modify {
        margin-top: 40px;
    }

    .logo-content__main-header {
        text-align: center;
    }

    .logo-content__main-text {
        text-align: center;
        margin-top: 20px;
    }

    .logo-content__contact {
        margin-top: 70px;
    }

    .logo-content__contact form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .logo-content__contact-input {
        width: 300px;
        height: 20px;
        font-size: 16px;
        margin-bottom: 20px;
    }

    .logo-content__main-container {
        margin-left: 0;
    }

    .logo-content__contact-button {
        height: 30px;
        font-size: 16px;
        padding: 2px 5px;
    }

    /* footer */
    .footer-socials {
        margin-left: 0;
    }

    .logo {
        width: 40px;
    }

    .footer-logo {
        width: 300px;
        transform: translateX(-12px);
    }

    .footer-text {
        margin-left: 12px;
        font-size: 10px;
        padding-left: 0px;
    }


    /* SLICK TRACK */
    .slick-track {
        height: 85vh;
    }
}


@media (max-width: 375px) and (max-height: 600px) {
    .logo-image {
        width: 50%;
    }

    .language {
        height: 5px;
        width: 40px;
    }

    .main-logo.first-slide {
        width: 170px;
    }

    .main-logo.main-logo__hand {
        width: 70%;
    }

    .text-content {
        font-size: 20px;
        line-height: 24px;
    }

    .hand-text {
        margin-top: 10px;
        font-size: 11px;
    }

    .hand-text.hand-text__modify {
        margin-top: 10px;
        font-size: 11px;
    }

    .logo-content__main {
        margin-top: 10px;
    }

    .logo-content__contact-input {
        width: 200px;
    }

    .main-logo.monitor-logo {
        width: 200px;
    }

    .logo-content__contact {
        margin-top: 20px;
    }

    .text-content.first-slide {
        font-size: 25px;
        line-height: 28px;
    }
}


@media (max-width: 375px) and (max-height: 667px) {
    .logo-image {
        width: 50%;
    }

    .language {
        height: 10px;
    }

    .main-logo.first-slide {
        width: 250px;
    }

    .main-logo.main-logo__hand {
        width: 70%;
    }

    .hand-text.hand-text__modify {
        margin-top: 10px;
        font-size: 14px;
    }

    .logo-content__main {
        margin-top: 10px;
    }

    .main-logo.monitor-logo {
        width: 200px;
    }

    .logo-content__contact {
        margin-top: 20px;
    }

    .text-content.first-slide {
        font-size: 25px;
        line-height: 28px;
    }
}


@media (max-width: 360px) and (max-height: 740px) {
    .logo-image {
        width: 50%;
    }

    .language {
        height: 10px;
    }

    .main-logo.first-slide {
        width: 240px;
    }

    .main-logo.main-logo__hand {
        width: 70%;
    }

    .hand-text.hand-text__modify {
        margin-top: 10px;
        font-size: 14px;
    }

    .logo-content__main {
        margin-top: 10px;
    }

    .main-logo.monitor-logo {
        width: 270px;
    }

    .logo-content__contact {
        margin-top: 20px;
    }

    .text-content.first-slide {
        font-size: 25px;
        line-height: 28px;
    }
}

/* 390px × 844p */

@media (max-width: 390px) and (max-height: 844px) {
    .main-logo.first-slide {
        width: 200px;
    }

    .main-logo.main-logo__hand {
        width: 280px;
    }

    .main-logo.monitor-logo {
        width: 200px;
    }

    .text-content.first-slide {
        font-size: 25px;
        line-height: 28px;
    }

    .text-content.hand-logo {
        font-size: 25px;
        line-height: 28px;
    }

    .hand-text.hand-text__modify {
        margin-top: 10px;
        font-size: 13px;
    }

    .slick-track {
        height: 73vh;
    }

    .logo-content__main {
        margin-top: 10px;
    }

    .logo-content__contact {
        margin-top: 10px;
    }
}